import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

import FlatDocumentation from "../components/flatDocumentation";
import EntrySection from "../components/entrySection";
import FlexSection from "../components/flexSection";
import FlatDetail from "../components/flatDetail";
import ModalImage from "../components/modalImage";
import PhoneBox from "../components/phoneBox";
import Footer from "../components/footer";
import config from "../config/config";
import Nav from "../components/nav";

export default function FlatPage() {
    const { t } = useTranslation();
    const { slug } = useParams();
    const [flat, setFlat] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFlatData = async () => {
            try {
                const response = await fetch(`${config.api_url}/flat?slug=${slug}`);
                if (!response.ok) {
                    throw new Error(`Error fetching flat data: ${response.statusText}`);
                }
                const data = await response.json();
                setFlat(data.data);
            } 
            
            catch (err) {
                setError(err.message);
            }
        };

        fetchFlatData();
    }, [slug]);

    return (
        <>
            <Nav />
            <main>
                <EntrySection size="half" />

                <section className="link-section"><Link to="/" className="return-back"><FontAwesomeIcon icon={faArrowLeft} />  {t("return_back")}</Link></section>

                {error ? (
                    <div className="error-flat-placeholder">
                        <h1 className="error-title">{t("unable_to_fetch_apartment")}</h1>
                        <p className="error-text">{t("try_it_later")}</p>
                    </div>
                    ) : flat ? (
                        <>
                            <FlatDetail 
                                floor={flat.floor}
                                apartnentNumber={flat.number}
                                type={flat.type}
                                priceWithoutVat={flat.priceWithoutVat}
                                vat={flat.vat}
                                vatRate={flat.vatRate}
                                priceWithVat={flat.priceWithVat}
                                fullSize={flat.fullSize}
                                flatSize={flat.flatSize}
                                commonSize={flat.commonSize}
                                landSize={flat.landSize}
                                terrace={flat.terrace}
                            />
                            
                            <FlatDocumentation 
                                planFull={`/assets/apartments/${flat.plan}`}
                                planMin={`/assets/apartments/${flat.planMin}`}
                                floorDocumentation={`/assets/pdf/${flat.floorDocumentation}`}
                                floorMin={`/assets/apartments/${flat.floorDocumentationImage}`}
                                documentation={`/assets/pdf/${flat.documentation}`}
                                documentationMin={`/assets/apartments/${flat.documentationImage}`}
                            />
                        </>
                    ) : (
                        <div className="error-flat-placeholder">
                            <h1 className="error-title">{t("loading")}...</h1>
                        </div>
                    )
                }

                <FlexSection title={t("about")} orientation="revert">
                    <ModalImage minImage="/assets/images/draft1-min.webp" fullImage="/assets/images/draft1.jpg" alt={t("about")} imageClass="flex-section-image" />
                    <p className="flex-section-text">{t("about_text")}</p>
                </FlexSection>

                <FlexSection>
                    <ModalImage minImage="/assets/images/draft2-min.webp" fullImage="/assets/images/draft2.jpg" alt={t("about")} imageClass="flex-section-image" />
                    <ModalImage minImage="/assets/images/draft3-min.webp" fullImage="/assets/images/draft3.jpg" alt={t("about")} imageClass="flex-section-image" />
                </FlexSection>

                <PhoneBox />

                <FlexSection title={t("where_to_find_us")} text={t("coordinates")}>
                <ModalImage minImage="/assets/images/pier-min.webp" fullImage="/assets/images/pier.jpg" alt={t("location")} imageClass="flex-section-image" />
                    
                    <div className="iframe-box">
                        <iframe 
                            title="map" 
                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d19174.792127618177!2d28.04192809956491!3d43.333718268776614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDPCsDIwJzM4LjQiTiAyOMKwMDMnNTguOSJF!5e0!3m2!1scs!2scz!4v1724353692180!5m2!1scs!2scz" 
                            width="100%" 
                            height="100%" 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade" />
                    </div>
                </FlexSection>
            </main>

            <Footer />
        </>
    );
}

