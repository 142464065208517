import { useTranslation } from "react-i18next";
export default function FlatDetail({
        apartnentNumber, 
        floor, 
        type, 
        priceWithoutVat, 
        vatRate, 
        vat, 
        priceWithVat,
        fullSize,
        flatSize,
        commonSize,
        landSize,
        terrace
    }) {

    const { t } = useTranslation();

    return <section className="flat-detail">
        <div className="detail-column center-column">
            <h1 className="apartment-title">{t("apartment")} {apartnentNumber}</h1>
        </div>

        <div className="detail-column">
            <ul>
                <li className="bold-line">{t("apartment")}: {apartnentNumber}</li>
                <li className="bold-line">{t("floor")}: {floor}</li>
                <li className="bold-line">{t("type")}: {type}</li>
                <li>{t("price_without_vat")}: {formatNumber(priceWithoutVat)} €</li>
                <li>{t("vat_rate")}: {vatRate} %</li>
                <li>{t("vat")}: {formatNumber(vat)} €</li>
                <li className="bold-line">{t("price_with_vat")}: {formatNumber(priceWithVat)} €</li>
                <li className="bold-line">{t("full_size")}: {formatNumber(fullSize)} m<sup>2</sup></li>
                <li>{t("flat_size")}: {formatNumber(flatSize)} m<sup>2</sup></li>
                <li>{t("common_size")}: {formatNumber(commonSize)} m<sup>2</sup></li>
                <li>{t("land_size")}: {formatNumber(landSize)} m<sup>2</sup></li>
                {terrace && <li>{t("terrace")}: {formatNumber(terrace)} m<sup>2</sup></li>}
            </ul>
        </div>
    </section>
}

function formatNumber(num) {
    return new Intl.NumberFormat('cs-CZ', { style: 'decimal', minimumFractionDigits: 0 }).format(num);
}