import { useTranslation } from "react-i18next";
import ModalImage from "./modalImage";
import ModalPdf from "./modalPdf";

export default function FlatDocumentation({planMin, planFull, floorMin, floorDocumentation, documentationMin, documentation}) {
    const { t } = useTranslation();

    return <section className="flat-documentation">
        <h1 className="flat-documentation-title">{t("documentation_for_the_apartment")}</h1>

        <div className="single-column row">
            <ModalImage labelText={t("click_for_zoom")} alt={t("documentation_for_the_apartment")} minImage={planMin} fullImage={planFull} className="column" />
        </div>

        <div className="double-column row">
            <ModalPdf alt={t("documentation_for_the_apartment")} minImage={floorMin} target={floorDocumentation} className="column" labelText={t("click_for_zoom")} />

            <ModalPdf alt={t("documentation_for_the_apartment")} minImage={documentationMin} target={documentation} className="column" labelText={t("click_for_zoom")} />
        </div>
    </section>
}