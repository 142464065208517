import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import React, { useState } from "react";

export default function ModalImage({minImage, fullImage, alt, imageClass, labelText}) {
    const [open, setOpen] = useState(false);

    const slides = [
        { src: fullImage }
    ];

    return (
        <>
            <div className="modal-image" onClick={() => setOpen(true)}>
                <img src={minImage} alt={alt} className={imageClass ? imageClass : ""} />
                { labelText && <div className="label-text"><h1>{labelText}</h1></div>}
            </div>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides}
                render={{
                    buttonPrev: false,
                    buttonNext: false
                }}
            />
        </>
    );
};
