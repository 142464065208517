import { useTranslation } from "react-i18next"

export default function EntrySection({size, text, showWaves}) {
    const { t } = useTranslation();
    return <section className={`${size ? size : ""} entry-section`}>
        <div className="entry-section-content">
            <h1 className="entry-section-title">{ t("name") }</h1>
            {text && <h2 className="entry-section-text">{ text }</h2>}
        </div>

        {   showWaves &&
            <svg className="entry-section-wave" viewBox="0 0 1440 323" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L60 7.69048C120 15.381 240 30.7619 360 61.5238C480 92.2857 600 138.429 720 169.19C840 199.952 960 215.333 1080 184.571C1200 153.81 1320 76.9048 1380 38.4524L1440 0V323H1380C1320 323 1200 323 1080 323C960 323 840 323 720 323C600 323 480 323 360 323C240 323 120 323 60 323H0V0Z" />
            </svg>
        }
    </section>
}