import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Button from "./button";

const GalleryModal = () => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const slides = [
        { src: "/assets/gallery/20221015_160424.jpg" },
        { src: "/assets/gallery/20240622_105046.jpg" },
        { src: "/assets/gallery/20240622_114630.jpg" },
        { src: "/assets/gallery/20240622_114636.jpg" },
        { src: "/assets/gallery/20240622_114826.jpg" },
        { src: "/assets/gallery/dji_fly_20240622_112754_0028_1719046722407_photo.jpg" },
        { src: "/assets/gallery/dji_fly_20240622_112806_0030_1719046713928_photo.jpg" },
        { src: "/assets/gallery/dji_fly_20240622_112824_0031_1719046707767_photo.jpg" },
        { src: "/assets/gallery/GOPR0166_1719048583387.JPG" },
        { src: "/assets/gallery/IMG_1141.jpg" },
        { src: "/assets/gallery/IMG_1145.jpg" },
        { src: "/assets/gallery/IMG_1175.jpg" },
        { src: "/assets/gallery/IMG_1215.JPG" },
        { src: "/assets/gallery/IMG_1228.jpg" },
        { src: "/assets/gallery/IMG_1754.jpg" },
        { src: "/assets/gallery/IMG_1756.jpg" },
        { src: "/assets/gallery/IMG_4343.JPG" },
        { src: "/assets/gallery/IMG_4495.JPG" },
        { src: "/assets/gallery/IMG_4682.JPG" },
        { src: "/assets/gallery/IMG_4683.JPG" },
        { src: "/assets/gallery/IMG_7240.JPG" },
        { src: "/assets/gallery/IMG_7253.JPG" },
        { src: "/assets/gallery/IMG_7258.JPG" },
        { src: "/assets/gallery/IMG_7259.jpg" },
        { src: "/assets/gallery/IMG_8181.jpg" },
        { src: "/assets/gallery/IMG_8182.jpg" },
        { src: "/assets/gallery/IMG_8234.jpg" },
        { src: "/assets/gallery/IMG_8510.JPG" },
        { src: "/assets/gallery/IMG_8511.JPG" },
        { src: "/assets/gallery/IMG_8514.JPG" },
        { src: "/assets/gallery/IMG_8515.JPG" },
        { src: "/assets/gallery/IMG_8517.JPG" },
        { src: "/assets/gallery/IMG_8520.JPG" },
        { src: "/assets/gallery/IMG_8522.JPG" },
        { src: "/assets/gallery/IMG_8525.JPG" },
        { src: "/assets/gallery/IMG_8526.jpg" },
        { src: "/assets/gallery/IMG_8527.JPG" },
        { src: "/assets/gallery/IMG_8530.JPG" },
        { src: "/assets/gallery/IMG_8534.JPG" },
    ];

    return (
        <>
            <Button text={t("all_photos")} aria-label={t("all_photos")} onClick={() => setOpen(true)} />

            <Lightbox
                plugins={[Counter, Thumbnails]}
                counter={{ container: { style: { top: "0", bottom: "unset" } } }}
                open={open}
                close={() => setOpen(false)}
                slides={slides}
            />
        </>
    );
};

export default GalleryModal;
