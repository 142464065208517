import { useTranslation } from "react-i18next";

export default function PhoneBox() {
    const { t } = useTranslation();

    return <section className="phone-box-container">
        <h1 className="phone-box-title">{t("let_us_know")}</h1>

        <div className="phone-box">
            <h1 className="phone-box-phone">
                <a href={ `tel:${t("phone").replaceAll(" ", "")}` }>
                    { t("phone") }
                </a>
            </h1>

        </div>
    </section>
}