export default function TextField({type, name, label, placeholder, required, value, onChange}) {
    return (
        <label className="text-field">
            {label && <span className="text-label">{label}</span>}
            <input 
                type={type} 
                name={name} 
                placeholder={placeholder ? placeholder : undefined} 
                required={required ? required : undefined} 
                value={value} 
                onChange={onChange} 
            />
        </label>
    );
}
