import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import LoginForm from "../components/loginForm";

export default function Login() {
    const navigate = useNavigate();
    const userData = sessionStorage.getItem("userData");

    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (loggedIn && userData != null) {
            navigate("/admin");
        }
    }, [navigate, userData]);

    return <div className="center-box">
        <LoginForm />
    </div>
}