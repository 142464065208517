import { useTranslation, Trans } from "react-i18next";

import ParagraphSection from "../components/paragraphSection";
import EntrySection from "../components/entrySection";
import GalleryModal from "../components/galleryModal";
import StepsSection from "../components/stepsSection";
import FlexSection from "../components/flexSection";
import ModalImage from "../components/modalImage";
import FlatsTable from "../components/flatsTable";
import VideoModal from "../components/videoModal";
import PhoneBox from "../components/phoneBox";
import Footer from "../components/footer";
import Nav from "../components/nav";

export default function IndexPage() {
    const { t } = useTranslation();
    return (
        <>
            <Nav />
            <main>
                <EntrySection text={t("entry_text")} showWaves="true" />
                <FlexSection title={t("about")}>
                    <ModalImage minImage="/assets/images/draft1-min.webp" fullImage="/assets/images/draft1.jpg" alt={t("about")} imageClass="flex-section-image" />
                    <p className="flex-section-text">{t("about_text")}</p>
                </FlexSection>

                <FlexSection orientation="reverse">
                    <ModalImage minImage="/assets/images/draft2-min.webp" fullImage="/assets/images/draft2.jpg" alt={t("about")} imageClass="flex-section-image" />
                    <p className="flex-section-text">{t("about_second_text")}</p>
                </FlexSection>

                <FlatsTable />

                <ParagraphSection title={t("location")} text={t("location_text")} />

                <FlexSection>
                    <ModalImage minImage="/assets/images/stadion-min.webp" fullImage="/assets/images/stadion.jpg" alt={t("about")} imageClass="flex-section-image" />

                    <div>
                        <p className="flex-section-text">
                            <Trans 
                                i18nKey="stadion_text"
                                components={ [<a href={t("link_to_stadion")} rel="noreferrer" target="_blank">{t("link_to_stadion")}</a>] }
                            />
                        </p>
                    </div>
                </FlexSection>

                <FlexSection orientation="reverse">
                    <ModalImage minImage="/assets/images/beach-min.webp" fullImage="/assets/images/beach.jpg" alt={t("about")} imageClass="flex-section-image" />
                    <p className="flex-section-text">{t("second_section")}</p>
                </FlexSection>

                <FlexSection>
                    <VideoModal videoSrc="/assets/video/video.mp4" poster="/assets/images/video-poster-min.webp" alt={t("location")} imageClass="flex-section-image flex-column" />
                    <div>
                        <p className="flex-section-text">{t("third_section")}</p>
                        <div className="center-button-row">
                            <GalleryModal />
                        </div>
                    </div>
                </FlexSection>

                <ParagraphSection title={t("purchase_process")} />

                <StepsSection text={t("reservation_contract")} />

                <StepsSection title={t("future_contract")} text={t("future_contract_text")} />
                <StepsSection title={t("shell_construction")} text={t("shell_construction_text")} />
                <StepsSection title={t("approval")} text={t("approval_text")} />

                <section className="steps-section">
                    <h1 className="steps-title">{t("property_transfer")}</h1>
                    <p className="steps-text">
                        <Trans 
                            i18nKey="property_transfer_text"
                            components={ [<a href={t("lawyer_web")} rel="noreferrer" target="_blank">{t("lawyer_web")}</a>] }
                        />
                    </p>
                </section>

                <PhoneBox />

                <FlexSection title={t("where_to_find_us")} text={t("coordinates")}>
                    <ModalImage minImage="/assets/images/pier-min.webp" fullImage="/assets/images/pier.jpg" alt={t("location")} imageClass="flex-section-image" />
                    
                    <div className="iframe-box">
                        <iframe 
                            title="map" 
                            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d19174.792127618177!2d28.04192809956491!3d43.333718268776614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDPCsDIwJzM4LjQiTiAyOMKwMDMnNTguOSJF!5e0!3m2!1scs!2scz!4v1724353692180!5m2!1scs!2scz" 
                            width="100%" 
                            height="100%" 
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade" />
                    </div>
                </FlexSection>
            </main>
            <Footer />
        </>
    )
}