export default function ModalPdf({minImage, target, alt, imageClass, labelText}) {
    return (
        <>
            <a href={target} className="modal-image" target="_blank" rel="noreferrer">
                <img src={minImage} alt={alt} className={imageClass ? imageClass : ""} />
                { labelText && <div className="label-text"><h1>{labelText}</h1></div>}
            </a>
        </>
    );
};
