import i18next, { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { languages } from "../i18n";

export default function LanguageSwitch() {
    const { t } = useTranslation();
    let cl;
    if(i18next.language.includes("-")) {
        const splited = cl = i18next.language.split("-");

        cl = splited[0];
    }

    else {
        cl = i18next.language;
    }

    const [currentLanguage, setCurrentLanguage] = useState(languages.find(lang => lang.code === cl));
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const handleLanguageChange = (lng) => {
            const newLanguage = languages.find(lang => lang.code === lng);
            setCurrentLanguage(newLanguage);
        };

        i18next.on('languageChanged', handleLanguageChange);

        return () => {
            i18next.off('languageChanged', handleLanguageChange);
        };
    }, []);

    const toggleClass = () => {
        setIsActive(!isActive);
    }

    return (
        <div className={`${isActive ? "active" : ""} language-switch`}>
            <div className="languages-indicator">
                <button onClick={toggleClass} aria-label={t("language_switch")} type="button">
                    {currentLanguage && <currentLanguage.flag />}
                </button>
            </div>

            <div className="languages-menu">
                <ul>
                    {languages.map((language, index) => (
                        <li key={index}>
                            <button 
                                aria-label={ language.name }
                                onClick={() => { 
                                    switchLanguage(language.code);
                                    toggleClass();
                                }}
                            ><language.flag title={language.name} /></button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

function switchLanguage(code) {
    changeLanguage(code);
}
