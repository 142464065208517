import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FlexSection from "../components/flexSection";
import config from "../config/config.json";
import Logout from "../components/logout";

export default function Admin() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [flats, setFlats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editFlatId, setEditFlatId] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const fetchFlats = useCallback(() => {
        setLoading(true);
        fetch(`${config.api_url}/admin/flats`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then((data) => {
                if (data.status === 401) {
                    navigate("/login");
                    return;
                }
    
                setFlats(data);
                setLoading(false);
                setError(null);
            })
            .catch((error) => {
                console.error('Error fetching flats:', error);
                setError(error.message);
                setLoading(false);
            });
    }, [navigate]);

    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (!loggedIn) {
            navigate("/login");
        }
        
        else {
            fetchFlats();
        }
    }, [navigate, fetchFlats]);

    const handleStatusChange = () => {
        fetch(`${config.api_url}/admin/flats/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: newStatus, flat: editFlatId }),
        })
            .then(async (res) => {
                if (!res.ok) {
                    const errorText = await res.text();
                    let errorData;
                    try {
                        errorData = JSON.parse(errorText);
                    } catch (e) {
                        console.log(errorText);
                    }
                    const errorMessage = errorData?.message || 'Failed to update status';
                    throw new Error(errorMessage);
                }
                return res.json();
            })
            .then((data) => {
                if (data.status === 401) {
                    navigate("/login");
                    return;
                }

                fetchFlats();
                setModalOpen(false);
                setEditFlatId(null);
                setNewStatus('');
            })
            .catch((error) => {
                console.error('Error updating flat status:', error.message);
                setError(error.message);
            });
    };

    const openModal = (flat) => {
        setEditFlatId(flat.flatID);
        setNewStatus(flat.status);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setEditFlatId(null);
        setNewStatus('');
    };

    return (
        <>
            <FlexSection>
                <h1 className="admin-title">Administrace webu</h1>
                <Logout />
            </FlexSection>
            <section className="admin-flats-table">
                <h1 className="flats-table-title">{t("flats_offering")}</h1>
                <div className="table-box">
                    <table className={`${loading ? "loading" : ""} ${error ? "error" : ""}`}>
                        <thead>
                            <tr>
                                <th>{t("apartment")}</th>
                                <th>{t("availability")}</th>
                                <th>{t("edit")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                Array.from({ length: 6 }).map((_, index) => (
                                    <tr key={index}>
                                        {Array.from({ length: 3 }).map((_, colIndex) => (
                                            <td key={colIndex}></td>
                                        ))}
                                    </tr>
                                ))
                            ) : error ? (
                                Array.from({ length: 6 }).map((_, index) => (
                                    <tr key={index}>
                                        {Array.from({ length: 3 }).map((_, colIndex) => (
                                            <td key={colIndex}></td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                flats.data.map((flat) => (
                                    <tr key={flat.id}>
                                        <td title={t("apartment") + " " + flat.number}>{t("apartment")} {flat.number}</td>
                                        <td title={t(flat.status.toLowerCase())}>{t(flat.status.toLowerCase())}</td>
                                        <td>
                                            <button onClick={() => openModal(flat)}>
                                                {t("edit")}
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    {error && (
                        <div className="table-cover">
                            <h1 className="error-title">{t("unable_to_fetch")}</h1>
                            <h2 className="error-description">{t("try_it_later")}</h2>
                        </div>
                    )}
                </div>
            </section>

            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{t("edit_apartment_status")}</h2>
                        <label htmlFor="status-select">{t("select_status")}</label>
                        <select
                            id="status-select"
                            value={newStatus}
                            onChange={(e) => setNewStatus(e.target.value)}
                        >
                            <option value="Available">{t("available")}</option>
                            <option value="Booked">{t("booked")}</option>
                            <option value="Sold">{t("sold")}</option>
                        </select>
                        <div className="modal-buttons">
                            <button onClick={handleStatusChange}>{t("save")}</button>
                            <button onClick={closeModal}>{t("cancel")}</button>
                        </div>
                    </div>
                    <div className="modal-overlay" onClick={closeModal}></div>
                </div>
            )}
        </>
    );
}
