import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Video from "yet-another-react-lightbox/plugins/video";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import Lightbox from "yet-another-react-lightbox";

import { useState } from "react";

export default function VideoModal({alt, poster, videoSrc, imageClass}) {
    const [open, setOpen] = useState(false);
    
    const slides = [
        {
            type: "video",
            poster: "/assets/video/video-poster.jpg",
            autoPlay: true,
            sources: [
                {
                    src: videoSrc,
                    type: "video/mp4",
                },
            ],
        }
    ]

    return <div className="video-modal">
        <div className="poster-box">
            <img src={poster} alt={alt} className={`${imageClass} video-poster`} onClick={() => setOpen(true)} />
            <div className="play-icon">
                <FontAwesomeIcon icon={faPlay} />
            </div>
        </div>

        <Lightbox
            plugins={[Video]}
            counter={{ container: { style: { top: "0", bottom: "unset" } } }}
            open={open}
            close={() => setOpen(false)}
            slides={slides}
        />
    </div>
}