import { CZ, US, DE, RU, BG } from "country-flag-icons/react/3x2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();
    return <footer>
        <div className="footer-content">
            <div className="footer-column">
                <h1 className="footer-title">{ t("name") }</h1>
                <ul>
                    <li>
                        <a href={ `tel:+420728156422` }>
                            +420 728 156 422
                        </a>
                        <CZ /> <US />
                    </li>

                    <li>
                        <a href={ `tel:+420775996099` }>
                            +420 775 996 099
                        </a>
                        <CZ /> <DE />
                    </li>

                    <li>
                        <a href={ `tel:+359892430343` }>
                            +359 892 430 343
                        </a>
                        <BG /> <RU />
                    </li>

                    <li>
                        <a href={ `mailto:${t("email")}` }>{ t("email") }</a>
                    </li>
                    <li>{ t("coordinates") }</li>
                </ul>
            </div>

            <div className="footer-column">
                <h1 className="footer-title">{ t("documents") }</h1>
                <ul>
                    <li>
                        <a href="/assets/pdf/disposition.pdf" target="_blank">
                            <FontAwesomeIcon icon={faFilePdf} />
                            { t("flat_disposition") }
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
}
