import { Routes, Route } from "react-router-dom";

import ScrollToTop from "./hooks/scrollToTop";
import FlatPage from "./pages/flatPage";
import Error404 from "./pages/404";
import Login from "./pages/login";
import Admin from "./pages/admin";
import IndexPage from "./pages";

function App() {
	return (
		<div className="App">
			<ScrollToTop />
			<Routes>
				<Route index path="/" element={<IndexPage />} />
				<Route path="/flat/:slug" element={<FlatPage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/admin" element={<Admin />} />
				<Route path="*" element={<Error404 />} />
			</Routes>
		</div>
	);
}

export default App;
