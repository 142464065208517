import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';

import config from "../config/config.json";

export default function Logout() {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const handleLogout = async () => {
        try {
            const response = await fetch(`${config.api_url}/admin/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                sessionStorage.removeItem("loggedIn");
                sessionStorage.removeItem("userData");
                sessionStorage.clear();

                navigate("/login");
            }
            
            else {
                toast.error(t("logout_failure"));
            }
        }
        
        catch (error) {
            toast.error(t("logout_failure"));
        }
    };

    return (
        <section className="logout-button-section">
            <button className="theme-button" onClick={handleLogout}>
                <FontAwesomeIcon icon={faRightFromBracket} /> {t("logout")}
            </button>
            <ToastContainer />
        </section>
    );
}
