import { useTranslation } from "react-i18next";

import Footer from "../components/footer";
import Nav from "../components/nav";

export default function Error404() {
    const { t } = useTranslation();

    return (
        <>
            <Nav />
            <div className="center-box">
                <h1>404</h1>
                <p>{t("not_found")}</p>
            </div>
            <Footer />
        </>
    )
}