import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import config from '../config/config';

export default function FlatsTable() {
    const { t } = useTranslation();
    
    const [flats, setFlats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${config.api_url}/flats`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            })
            .then((data) => {
                setFlats(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching flats:', error);
                setError(error.message);
                setLoading(false);
            });
    }, []);

    return (
        <section className="flats-table">
            <h1 className="flats-table-title">{t("offer_of_flats")}</h1>
            <div className="table-box">
                <table className={ `${loading ? "loading" : ""} ${error ? "error" : ""}` }>
                    <thead>
                        <tr>
                            <th data-important="true">{t("apartment")}</th>
                            <th data-important="true">{t("living_area")}</th>
                            <th>{t("rooms")}</th>
                            <th>{t("price")}</th>
                            <th>{t("availability")}</th>
                            <th data-important="true">{t("detail")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <TablePlaceholder />
                        ) : error ? (
                            <TablePlaceholder />
                        ) : (
                            flats.data.map((flat, index) => (
                                <tr key={index} data-status={flat.status.toLowerCase() !== "available" ? "unavailable" : "available"}>
                                    <td data-important="true" title={t("apartment") + " " + flat.number}>{t("apartment")} {flat.number}</td>
                                    <td data-important="true" title={`${flat.flatSize} m²`}>{flat.flatSize} m<sup>2</sup></td>
                                    <td title={flat.type}>{flat.type}</td>
                                    <td title={`${formatNumber(flat.priceWithVat)} €`}>{formatNumber(flat.priceWithVat)} €</td>
                                    <td title={t(flat.status.toLowerCase())}>{t(flat.status.toLowerCase())}</td>
                                    <td data-important="true" className='sandy'>{flat.status.toLowerCase() !== "available" ? "" : <Link to={`/flat/${flat.slug}`}>{t("more_informations")}</Link>}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                { error && <div className="table-cover"><h1 className="error-title">{t("unable_to_fetch")}</h1><h2 className="error-description">{t("try_it_later")}</h2></div> }
            </div>
        </section>
    );
}

function formatNumber(num) {
    return new Intl.NumberFormat('cs-CZ', { style: 'decimal', minimumFractionDigits: 0 }).format(num);
}

function TablePlaceholder() {
    return Array.from({ length: 6 }).map((_, index) => (
        <tr key={index}>
            {Array.from({ length: 6 }).map((_, colIndex) => (
                <td key={colIndex}></td>
            ))}
        </tr>
    ))
}
