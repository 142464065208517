import { Children, cloneElement } from "react";

export default function FlexSection({title, text, orientation, children}) {
    return <div className={`flex-section ${orientation ? 'reverse' : ''}`}>
        {title && <h1 className="flex-section-title">{title}</h1>}
        {text && <p className="flex-section-text">{text}</p>}

        <div className="flex-section-content">
            { Children.map(children, child =>
                cloneElement(child, {
                    className: `${child.props.className} ${"flex-column"}`
                })
            )}
        </div>
    </div>
}