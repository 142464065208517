import LanguageDetector from 'i18next-browser-languagedetector';
import { /*US,*/ CZ } from 'country-flag-icons/react/3x2';

import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import i18n from 'i18next';

import config from "./config/config.json";

export const languages = [
    { "code": "cs", "name": "Česky", "flag": CZ },
    //{ "code": "en", "name": "English", "flag": US },
]

const i18noptions = {
    supportedLngs: ['cs'],
}

let navigatorLang = navigator.language ? navigator.language.includes("-") ? navigator.language.split("-")[0] : navigator.language : i18noptions.supportedLngs[0]
navigatorLang = (i18noptions.supportedLngs.includes(navigatorLang) ? navigatorLang : i18noptions.supportedLngs[0])

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: i18noptions.supportedLngs,
        debug: config.debug,
        lng: navigatorLang,
        fallbackLng: i18noptions.supportedLngs[0],
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'htmlTag', 'navigator'],
            caches: ['localStorage']
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        },
        react: { useSuspense: false },
        initImmediate: false,
    });

export default i18n;