import { ToastContainer, toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';

import TextField from "../components/textField";
import Button from "../components/button";
import config from "../config/config";

export default function LoginForm() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: '',
        password: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const submitLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${config.api_url}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                const textResponse = await response.text();
                let data;
                try {
                    data = JSON.parse(textResponse);
                }

                catch(e) {
                    console.error(textResponse);
                }

                sessionStorage.setItem("loggedIn", true);
                sessionStorage.setItem("userData", JSON.stringify(data.data));

                navigate("/admin");
                return;
            }
            
            else {
                const textResponse = await response.text();
                let errorData;
                try {
                    errorData = JSON.parse(textResponse);
                }

                catch(e) {
                    console.error(textResponse);
                }

                if(response.status === 401) {
                    toast.error(`Přihlášení se nezdařilo: Nesprávné přihlašovací údaje`);
                    return;
                }

                toast.error(`Přihlášení se nezdařilo${errorData.message ? ": " + errorData.message : ""}`);
                console.error('Login failed:', errorData);
            }
        } 
        
        catch (error) {
            toast.error(`Došlo k chybě: ${error.message}`);
            console.error('An error occurred:', error);
        }
    };

    return (
        <>
            <form className="login-form" onSubmit={submitLogin}>
                <h1>Vítejte zpět</h1>

                <TextField 
                    name="name" 
                    type="email" 
                    placeholder="Uživatelské jméno" 
                    label="Uživatelské jméno" 
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <TextField 
                    name="password" 
                    type="password" 
                    placeholder="Heslo" 
                    label="Heslo" 
                    value={formData.password}
                    onChange={handleChange}
                    required
                />

                <Button text="Přihlásit se" type="submit" />

                <Link to="/" className="theme-link">Zpět na úvodní stránku</Link>
            </form>
            <ToastContainer />
        </>
    );
}
